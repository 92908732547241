import React, { useState } from 'react';
import emailjs from 'emailjs-com';

export default function ContactMe() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    topic: '',
    message: ''
  });

  const [alert, setAlert] = useState({
    show: false,
    message: '',
    type: '' // 'success' or 'error'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send('service_fv0p6q2', 'template_v4i7uqg', formData, 'ijg3E3P1xR4AS_Krn')
      .then((response) => {
        setAlert({
          show: true,
          message: 'Email sent successfully!',
          type: 'success'
        });
      }, (err) => {
        setAlert({
          show: true,
          message: 'Failed to send email. Please try again later.',
          type: 'error'
        });
      });
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, show: false });
  };

  return (
    <section id="Contact" className="contact--section">
      <div>
        <p className="sub--title">Get In Touch</p>
        <h2>Contact Me</h2>
        <p className="text-lg">
          Have any questions or opportunities? Don’t hesitate to get in touch or request my full resume.
        </p>
      </div>
      <form className="contact--form--container" onSubmit={handleSubmit}>
        <div className="container">
          <label htmlFor="firstName" className="contact--label">
            <span className="text-md">First Name</span>
            <input
              type="text"
              className="contact--input text-md"
              name="firstName"
              id="firstName"
              required
              value={formData.firstName}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="lastName" className="contact--label">
            <span className="text-md">Last Name</span>
            <input
              type="text"
              className="contact--input text-md"
              name="lastName"
              id="lastName"
              required
              value={formData.lastName}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="email" className="contact--label">
            <span className="text-md">Email</span>
            <input
              type="email"
              className="contact--input text-md"
              name="email"
              id="email"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="phoneNumber" className="contact--label">
            <span className="text-md">Phone Number</span>
            <input
              type="number"
              className="contact--input text-md"
              name="phoneNumber"
              id="phoneNumber"
              required
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </label>
        </div>
        <label htmlFor="topic" className="contact--label">
          <span className="text-md">Choose a topic</span>
          <select id="topic" className="contact--input text-md" name="topic" value={formData.topic} onChange={handleChange}>
            <option value="">Select One...</option>
            <option value="Resume request">Resume request</option>
            <option value="Question">Question</option>
            <option value="New opportunity">New opportunity</option>
          </select>
        </label>
        <label htmlFor="message" className="contact--label">
          <span className="text-md">Message</span>
          <textarea
            className="contact--input text-md"
            id="message"
            name="message"
            rows="8"
            placeholder="Type your message..."
            value={formData.message}
            onChange={handleChange}
          />
        </label>
        <div>
          <button className="btn btn-primary contact--form--btn" type="submit">Submit</button>
        </div>
      </form>
      {alert.show && (
        <div className={`alert alert-${alert.type}`}>
          <span>{alert.message}</span>
          <button onClick={handleCloseAlert}>Close</button>
        </div>
      )}
    </section>
  );
}

