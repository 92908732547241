export default function AboutMe() {
    return (
      <section id="AboutMe" className="about--section">
        <div className="about--section--img">
          <img src="./img/about-me1.png" alt="About Me" />
        </div>
        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
            <p className="section--title">About</p>
            <h1 className="skills-section--heading">About Me</h1>
            <p className="hero--section-description">
            Hello! I’m Akuien Akoi Deng, a passionate and motivated junior software engineer. 
            With a strong foundation in JavaScript, Python, Java and C++.
            I am enthusiastic about developing innovative software solutions that make a difference.
            </p>
            <p className="hero--section-description">
            I recently graduated from the University of Gothenburg with a bachelors degree in Software Engineering & Management, 
            where I honed my skills in full-stack development, web applications, software design and testing. 
            During my academic journey, I worked on various projects that challenged me to learn and adapt quickly, 
            fostering a solid understanding of Data structures and algorithms, software architecture, software design, testing and analysis, RE/UX, React,
            Node.js, Vue.js, APIs,Distributed and embedded systems, Agile development, Machine learning & Deep learning.
            </p>
          </div>
        </div>
      </section>
    );
  }
  