export default function HeroSection() {
    return (
      <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            <p className="section--title">Hey, I'm Akuien</p>
            <h1 className="hero--section--title">
              <span className="hero--section-title--color">Software Engineer</span>{" "}
            </h1>
            <p className="hero--section-description">
            A passionate and motivated junior software engineer with a bachelors degree in Software Engineering & Management from the University of Gothenburg.
            <br />
            I am enthusiastic about developing innovative software solutions that make a difference.            
            <br />
            Feel free to explore my portfolio to see some of the projects I’ve worked on. If you have any questions or opportunities, don’t hesitate to get in touch!
            </p>
          </div>
          {/* <button className="btn btn-primary">Get In Touch</button> */}
        </div>
        <div className="hero--section--img">
          <img src="./img/profile.png" alt="Hero Section" />
        </div>
      </section>
    );
  }
  